import * as React from 'react'
import * as styles from './navModal.module.css'
import { CgClose } from 'react-icons/cg'
import { NavMenu } from './navMenu'
import { Watermark } from './watermark'

function NavModal(props) {
	
	const modalClass = props.open ? styles.modalOpen : styles.modal

	return (
		<div className={modalClass}>
			<span className={styles.closeWrapper}>
				<Watermark modal={true}/>
				<CgClose className={styles.close} onClick={props.toggleNavModal}/>
			</span>
			<NavMenu modal={true}/>
		</div>
	)
}

export { NavModal }