import * as React from 'react'
import * as styles from './watermark.module.css'

function Watermark(props) {

	const watermarkClass = props.modal ? styles.watermarkModal : styles.watermark

	return (
		<a 
			href='https://github.com/KeilerSwanson' 
			rel='noreferrer' 
			target='_blank' 
			className={watermarkClass}
		>
			Built by Keiler
		</a>
	)
}

export { Watermark }