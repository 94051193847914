import * as React from 'react'
import * as styles from './navMenu.module.css'
import { MenuItem } from './menuItem'
import { Dropdown } from './dropdown'

function NavMenu(props) {

	const navMenuClass = props.modal ? styles.navMenuModal : styles.navMenu

		return (
			<div className={navMenuClass}>
				<Dropdown modal={props.modal} />
				<MenuItem modal={props.modal} shop={true} path='/' title='Shop' />
				<MenuItem modal={props.modal} path='/about' title='About' />
				<MenuItem modal={props.modal} path='/contact' title='Contact' />
			</div>
		)
}

export { NavMenu }