import * as React from 'react'
import * as styles from './dropdown.module.css'
import { VscArrowDown } from 'react-icons/vsc'
import { MenuItem } from './menuItem'

function Dropdown(props) {
	const [dropdownOpen, setDropdownOpen] = React.useState(true)

	const dropdownClass = dropdownOpen ? styles.dropdownOpen : styles.dropdown

	function dropdownToggle() {
		if (dropdownOpen) {
			setDropdownOpen(false)
		} else {
			setDropdownOpen(true)
		}
	}

	return (
		<span className={styles.dropdownWrapper}>
			<h2 className={styles.dropdownTitle} onClick={dropdownToggle} >Gallery <VscArrowDown className={styles.dropdownIcon}/></h2>
			<span className={dropdownClass}>
				<MenuItem modal={props.modal} path='/stills' title='Stills & Interiors' category={true} />
				<MenuItem modal={props.modal} path='/figurative' title='Figurative' category={true} />
				<MenuItem modal={props.modal} path='/landscapes' title='Landscapes' category={true} />
				<MenuItem modal={props.modal} path='/commissions' title='Commissions' category={true} />
			</span>
		</span>
	)
}

export { Dropdown }