import * as React from 'react'
import * as styles from './mainSection.module.css'

function MainSection(props) {

	return (
		<div id='main-section' className={styles.mainSection} >
			{props.children}
		</div>
	)
}

export { MainSection }