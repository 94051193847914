import * as React from 'react'
import * as styles from './menuItem.module.css'
import { Link } from 'gatsby'

function MenuItem(props) {

	const itemClass = props.category ? styles.category : styles.menuItem

	if (props.shop) {
		return (
			<a 
				href='https://www.ebay.com/sch/bemor-2285/m.html?_nkw=&_armrs=1&_ipg=&_from=' 
				rel='noreferrer' 
				target='_blank' 
				className={itemClass}
			>
				<h2 className={styles.itemTitle}>{props.title}</h2>
				<span className={styles.itemSlider} />
			</a>
		)
	}

	return (
		<Link to={props.path} className={itemClass} activeClassName={styles.active}>
			<h2 className={styles.itemTitle} >{props.title}</h2>
			<span className={styles.itemSlider} />
		</Link>
	)
}

export { MenuItem }