import * as React from 'react'
import { Layout } from '../components/layout'

export default function AvailablePage() {
	// document.title = 'Available Work | BRachelle'

	return (
		<Layout>
			<h1>This is an available work page.</h1>
		</Layout>
	)
}