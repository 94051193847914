import * as React from 'react'
import { Navigation } from './navigation'
import { MainSection } from './mainSection'
import * as styles from './layout.module.css'

function Layout(props) {

	return (
		<div className={styles.layout}>
			<Navigation />
			<MainSection>
				{props.children}
			</MainSection>
		</div>
	)
}

export { Layout }