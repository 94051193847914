import * as React from 'react'
import { NavMenu } from './navMenu'
import * as styles from './navigation.module.css'
import { CgMenuRight } from 'react-icons/cg'
import BRLogo from '../img/android-chrome-512x512-2.png'
import { NavModal } from './navModal'
import { Watermark } from './watermark'
// import { StaticImage } from 'gatsby-plugin-image'

function Navigation(props) {
	const [modalOpen, setModalOpen] = React.useState(false)

	function toggleNavModal() {
		if (modalOpen) {
			setModalOpen(false)
		} else {
			setModalOpen(true)
		}
	}

	return (
		<div className={styles.navigation}>
			<span style={{backgroundImage: 'url(' + BRLogo + ')'}} className={styles.logo}/>
			{/* <StaticImage src='../img/android-chrome-512x512-2.png' className={styles.logo} /> */}
			<NavMenu />
			<CgMenuRight onClick={toggleNavModal} className={styles.menuIcon}/>
			<NavModal open={modalOpen} toggleNavModal={toggleNavModal}/>
			<Watermark />
		</div>
	)
}

export { Navigation }